require('./bootstrap');
import $ from 'jquery';
import Swal from "sweetalert2/dist/sweetalert2";
const AOS = require("aos");
import "lazysizes";

$(window).on("load", function(){
    $('.loader-wrapper').fadeOut("slow");
});
AOS.init();
$(document).ready(function () {



        const toggle = $('#header-toggle'),
              nav = $('#nav-bar'),
              bodypd = $('#body-pd'),
              headerpd = $('#header');

        if(toggle && nav && headerpd){
            toggle.on('click', function (){
                // show navbar
                nav[0].classList.toggle('show')
                // change icon
                toggle[0].classList.toggle('bx-x')
                // add padding to body
                bodypd[0].classList.toggle('body-pd')
                // add padding to header
                headerpd[0].classList.toggle('body-pd')
            })

        }

    // Cacher et montrer le boutton scroll-to-top
    $(document).scroll(function() {

        let scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });

    $('.no-external-verification').on('click', function (){
        Swal.fire({
            title: 'Aucune vérification d\'un organisme indépendant pour cette certification',
            icon:"info",
            showConfirmButton: false,
            timer:2500
            }
        )
    })
});
